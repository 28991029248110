<template>
  <div :class="imgBackground">
    <ValidationObserver ref="obs" v-slot="{ passes }">
      <div class="community">
        <v-container>
          <v-row justify="center" class="mt-md-0">
            <v-col cols="12" md="5" xl="4">
              <h3 class="white--text font-weight-bold mb-0">
                SÉ PARTE DE NUESTRA COMUNIDAD
              </h3>
              <p class="my-0 white--text text-community">
                Mantenete informado sobre nuevos lanzamientos, ofertas,
                promociones y mucho mas
              </p>
            </v-col>
            <v-col col="12" md="4" xl="2">
              <ValidationProvider
                name="correo"
                rules="email|required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="email"
                  solo
                  inverted
                  dense
                ></v-text-field>
                <div
                  v-show="errors.length"
                  class="white--text text-center my-0 py-0"
                  style="font-size: 0.9em"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
              <v-btn
                @click="passes(HandlerSuscribe)"
                block
                color="black"
                class="white--text"
                rounded
                large
              >
                SUSCRIBIRME
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12">
              <div
                class="d-flex justify-md-center align-md-center"
                style="height: 48px"
              >
                <div class="text-follow mr-15">SEGUINOS</div>
                <div class="d-flex">
                  <v-avatar
                    @click="goTo('https://www.facebook.com/stadioargentina/')"
                    style="cursor: pointer"
                  >
                    <img src="../../assets/footer/FACEBOOK.svg" alt="FB" />
                  </v-avatar>
                  <v-avatar
                    class="mx-2"
                    @click="goTo('https://www.instagram.com/stadio.argentina/')"
                    style="cursor: pointer"
                  >
                    <img src="../../assets/footer/INSTAGRAM.svg" alt="INS" />
                  </v-avatar>
                  <v-avatar>
                    <img src="../../assets/footer/YOUTUBE.svg" alt="YB" />
                  </v-avatar>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </ValidationObserver>

    <div class="social">
      <v-container>
        <v-row class="d-flex justify-md-space-around">
          <v-col cols="6" sm="6" md="3">
            <img src="../../assets/footer/STADIO.svg" alt="" />
            <div
              class="text-privacy mt-n2 mt-md-n8 ml-md-5"
              @click="showTerms = true"
            >
              Política de privacidad - Términos de uso
            </div>
            <div class="text-privacy ml-md-5">
              © STADIO 2021 Todos los derechos reservados
            </div>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="3"
            class="d-flex align-start align-md-center mt-5 mt-md-0"
          >
            <img width="100%" src="../../assets/footer/CACE.svg" alt="" />
          </v-col>
          <v-col cols="9" sm="8" md="3" lg="3" class="d-flex align-center">
            <div class="d-flex align-center mt-2">
              <span class="text-secretary text-end mr-2 mr-md-5 white--text">
                Cumplimos con la Resolución 270/2020 de la Secretaría de
                Comercio Interior del Ministerio de Desarrollo Productivo sobre
                la protección del Consumidor en el Comercio Electrónico
              </span>
              <a
                href="http://qr.afip.gob.ar/?qr=5n7kOdfOgUiazvIPvWJ9dg,,"
                target="_F960AFIPInfo"
                ><img
                  width="90px"
                  height="123px"
                  src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                  border="0"
                  alt=""
              /></a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      email: "",
      showError: false,
    };
  },

  computed: {
    imgBackground() {
      if (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      ) {
        return "";
      }

      return "img-background";
    },
  },

  methods: {
    async HandlerSuscribe() {
      try {
        this.loading = true;
        const request = {
          email: this.email,
          store_id: 1,
        };
        await this.$store.dispatch("products/SUSCRIBE_EMAIL", request);
        this.email = "";
        this.$refs.obs.reset();
        this.$snotify.success("Solicitud enviada", "Exitos!");
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    goTo(red) {
      window.open(red);
    },
  },
};
</script>

<style>
.img-background {
  background-image: url("../../../src/assets/footer/1920_ presonus-eris-e5-bt-lifestyle-04.png");
  width: 100%;
  max-height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.community {
  background-color: #832a3eed;
  height: auto;
}

.text-community {
  font-size: 1.2em;
}

.text-follow {
  color: white;
  font-size: 1.2em;
  font-weight: 600;
}

.social {
  background-color: #16131aa6;
  /* height: auto; */
}

/* @media (min-width: 700px) {
  .social {
    background-color: #16131aa6;
    height: 56.5%;
  }
} */

.text-secretary {
  font-weight: 300;
}

.text-privacy {
  font-size: 1em;
  color: white;
  font-weight: 300;
  cursor: pointer;
}
</style>
