<template>
  <section id="categorias" class="">
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="3"
        md="3"
        v-for="(item, index) in categoria"
        :key="index"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            flat
            color="#ECEBE5"
            @click="HandlerLocation(item)"
            class="mx-1 my-1"
            :style="
              hover
                ? 'border-bottom: 2px solid #A81331'
                : 'border-bottom: 2px solid #ECEBE5'
            "
          >
            <v-img
              contain
              class="img-destcados"
              :src="item.image_url"
              :lazy-src="item.image_url"
            >
              <v-row style="height: 100%">
                <v-col cols="12" class="mt-auto">
                  <div
                    class="
                      black--text
                      font-weight-medium
                      text-uppercase text-center
                    "
                    style="font-size: 1.2em"
                  >
                    {{ item.text_title }}
                  </div>
                </v-col>
              </v-row>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="black lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  props: {
    categoria: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    HandlerLocation(url) {
      const category_id = JSON.parse(url.category_id);
      this.$router.push({ name: "products", query: { data: category_id } });
    },
  },
};
</script>

<style>
.img-destcados {
  width: 100%;
  height: 350px;
}
</style>
