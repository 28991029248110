<template>
  <div
    @mouseleave="close()"
    class="mega_menu animate__animated animate__fadeIn animate__faster"
    style="background-color: #f8f8f8; height: 100erm"
  >
    <vue-scroll
      :ops="{
        bar: { background: '#AF2641', keepShow: true },
      }"
    >
      <v-container>
        <v-row class="mb-10">
          <v-col
            class="py-0"
            cols="12"
            md="3"
            v-for="(category, index) in categories"
            :key="index"
          >
            <v-hover v-slot="{ hover }">
              <div
                @click="HandlerGetPublicProducts(category, 1)"
                :style="
                  hover
                    ? 'font-size: 1.1em; cursor: pointer; color: #a81331'
                    : 'font-size: 1.1em; cursor: pointer'
                "
                class="mt-5 mb-3 text-capitalize"
              >
                {{ category.name }}
              </div>
            </v-hover>
            <div
              v-if="
                category.name != 'Línea S' &&
                category.name != 'Gaming' &&
                category.name != 'Todo' &&
                category.name != 'Portátiles'
              "
            >
              <div
                v-for="(sub_cat, j) in category.sub_category"
                :key="j"
                class="text-capitalize mb-1"
                style="font-size: 0.9em"
              >
                <span
                  @click="HandlerGetPublicProducts(sub_cat, 2)"
                  style="cursor: pointer"
                >
                  {{ sub_cat.name }}
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </vue-scroll>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  methods: {
    close() {
      this.$emit("close:change");
    },

    async HandlerGetPublicProducts(item, value) {
      if (value == 1) {
        const category_id = JSON.parse(item.id);
        this.$router
          .push({ name: "products", query: { data: category_id } })
          .catch((err) => err);
      } else {
        const sub_category_id = JSON.parse(item.id);
        this.$router
          .push({
            name: "products",
            query: { sub_data: sub_category_id },
          })
          .catch((err) => err);
      }
      this.close();
    },
  },
};
</script>

<style>
</style>