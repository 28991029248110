<template>
  <v-container fluid class="mx-0 px-0 py-0 my-0">
    <v-row justify="center" align="center" no-gutters>
      <information-component
        :message="'Iniciá sesión para mantenerte informado de nuestros modelos, y servicios que tenemos para vos.'"
        :logo="true"
      />
      <v-col cols="12" md="7">
        <v-img
          class="mx-auto mt-10 cursor-pointer"
          contain
          width="250"
          src="@/assets/img/logo_stadio.svg"
          lazy-src="@/assets/img/logo_stadio.svg"
          v-if="$vuetify.breakpoint.smAndDown"
          @click="$router.push('/')"
        ></v-img>
        <div class="d-flex justify-center mt-5">
          <!-- LOGIN CARD -->
          <login-card-component
            :emailUser="email"
            :passwordUser="password"
            v-if="show"
            @login:change="HandlerChange"
            @recovery:change="HandlerRecovery"
          />
          <!-- REGISTER CARD -->
          <register-card-component
            v-else
            @register:change="HandlerChangeReg"
            @verification:change="HandlerValidated"
          />
        </div>
      </v-col>
    </v-row>

    <!-- VALIDAR CUENTA -->
    <verification-account-component
      :showVerification="showVerification"
      :statusRegister="statusRegister"
      :emailUser="email"
      v-if="showVerification"
      @codeVerification:change="HandlerVerification"
    />

    <!-- RECUPERAR CONTRASEÑA -->
    <recovery-component
      :showRecovery="showRecovery"
      :showNotificationEmail="showNotificationEmail"
      @recoveryPassword:change="recoveryPassword"
    />
  </v-container>
</template>

<script>
import InformationComponent from "./AuthUtils/InformationComponent.vue";
import loginCardComponent from "./AuthUtils/loginCardComponent.vue";
import RegisterCardComponent from "./AuthUtils/RegisterCardComponent.vue";
import VerificationAccountComponet from "./AuthUtils/Dialogs/VerificationAccountComponet.vue";
import recoveryPassword from "./AuthUtils/recoveryPassword.vue";
export default {
  components: {
    "information-component": InformationComponent,
    "login-card-component": loginCardComponent,
    "register-card-component": RegisterCardComponent,
    "verification-account-component": VerificationAccountComponet,
    "recovery-component": recoveryPassword,
  },
  data() {
    return {
      showVerification: false,
      showNotificationEmail: false,
      showNotification: false,
      showRecovery: false,
      statusRegister: false,

      email: "",
      password: "",

      loading: false,
      loading_verification: false,
      email_verifiction: "",

      //Animation
      show: true,
    };
  },

  methods: {
    HandlerChange() {
      this.show = !this.show;
    },

    HandlerChangeReg() {
      this.show = !this.show;
    },

    HandlerRecovery() {
      this.showRecovery = !this.showRecovery;
    },

    HandlerValidated(user) {
      this.statusRegister = true;
      this.showVerification = true;
      this.email = user.email;
      this.password = user.password;
    },

    HandlerVerification() {
      this.show = !this.show;
      this.showVerification = false;
    },

    recoveryPassword() {
      this.showRecovery = false;
    }
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
