<template>
  <v-carousel
    v-model="item"
    hide-delimiters
    height="100%"
    :interval="24000"
    :hide-delimiter-background="false"
    :show-arrows-on-hover="false"
    :show-arrows="false"
    cycle
    next-icon="mdi-chevron-right"
    v-if="carrusel.length"
  >
    <v-carousel-item
      contain
      v-for="(item, i) in carrusel"
      :key="i"
      reverse-transition="fade-transition"
      transition="fade-transition"
    >
      <v-img :src="item.image_url" style="height: 37vmax">
        <v-container fill-height>
          <v-row justify="start" no-gutters>
            <div class="d-flex">
              <v-icon :color="item.color" @click="HandlerLeft" class="ml-n1">
                mdi-chevron-left
              </v-icon>
              <v-icon :color="item.color" @click="HandlerRight">
                mdi-chevron-right
              </v-icon>
            </div>
            <v-col cols="12" md="12">
              <div
                class="font-weight-bold title-carrusel"
                :style="`color: ${item.color}`"
              >
                {{ item.text_title }}
              </div>
            </v-col>
            <v-col cols="12" md="6" class="mt-2">
              <div class="" :style="`color: ${item.color}`">
                {{ item.text_body }}
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <div class="mb-0 price-carrusel" :style="`color: ${item.color}`">
                <div v-if="item.price == ' ' || item.price == null">
                  <div class="space-price-null"></div>
                </div>
                <div>
                  {{ item.price }}
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="12" >
              <v-btn
              v-if="item.url"
                :small="$vuetify.breakpoint.smAndDown ? true : false"
                color="#a81331"
                rounded
                class="elevation-0 button-carrusel"
              >
                <a
                  :href="item.url"
                  style="color: inherit; text-decoration: none"
                >
                  conocelo en detalle
                </a>
              </v-btn>
              <div v-else style="height:80px">

              </div>
            </v-col>
            <!-- <v-col cols="12" md="12">
              <div class="d-flex mt-4">
                <v-btn color="#495358" fab x-small>
                  <v-icon> mdi-share-variant </v-icon>
                </v-btn>
                <span :style="`color: ${item.color}`" class="share_carrusel">
                  compartir
                </span>
              </div>
            </v-col> -->
          </v-row>
          <v-row
            class="hidden-sm-and-down"
            justify="center"
            :no-gutters="false"
          >
            <v-col
              v-for="(val, index) in item.features"
              :key="index"
              style="background-color: rgb(255 255 255 / 60%)"
              class="mr-1"
              :md="item.features.length == 4 ? '' : 3"
            >
              <p
                class="
                  black--text
                  mr-1
                  text-uppercase text-center
                  mb-0
                  features-carrusel
                "
              >
                {{ val }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-carousel-item>
  </v-carousel>
  <div v-else>
    <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  props: {
    carrusel: {
      type: Array,
      requried: true,
      default: () => [],
    },
  },

  data() {
    return {
      item: 0,
    };
  },

  methods: {
    HandlerLeft() {
      this.item -= 1;
    },
    HandlerRight() {
      this.item += 1;
    },

    breakpointCss() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return "width: 100%; height: 500";
        case "md":
          return "width: 100%; height: 400";
        case "sm":
          return "width: 100%; height: 200";
        case "xs":
          return "width: 100%; height: 200";
        default:
          return "width: 100%; height: 500";
      }
    },

    goToForm() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style>
.v-carousel__controls__item {
  background: none;
  background-color: black !important;
}

.v-carousel__controls__item:hover {
  background: none;
  background-color: #af2641 !important;
}

.theme--dark.v-btn--active:before,
.theme--dark.v-btn--active:hover:before {
  opacity: 1 !important;
  background: #af2641;
}

.v-carousel__controls__item:hover {
  background: none;
  background-color: #af2641 !important;
}

.v-carousel__controls__item .v-icon {
  opacity: 0 !important;
}

.v-btn--icon.v-size--small {
  height: 20px !important;
  width: 20px !important;
}
</style>
