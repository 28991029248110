<template>
  <v-dialog v-model="showVerification" max-width="500" persistent>
    <ValidationObserver ref="obs" v-slot="{ passes }">
      <v-card>
        <v-card-title>
          Verifica tu cuenta con el codigo enviado al correo
        </v-card-title>
        <v-card-text class="mt-2 mb-0 pb-0">
          <ValidationProvider
            name="Codigo"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="code"
              label="Codigo de verificación"
              filled
              dense
              color="#A81331"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close(false)" text>Cancelar</v-btn>
          <v-btn @click="passes(HandlerValidateAccount)" dark color="#A81331">
            Validar
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showVerification: {
      type: Boolean,
      required: true,
      default: false,
    },
    statusRegister: {
      type: Boolean,
      required: true,
      default: false,
    },
    emailUser: {
      type: String,
      default: "",
      required: false,
    },
  },

  data() {
    return {
      loading: false,
      code: "",
    };
  },

  methods: {
    async HandlerValidateAccount() {
      try {
        this.loading = true;
        const request = {
          code: this.code,
          email: this.emailUser,
          store_id: 1,
        };
        await this.$store.dispatch("auth/CODE_VERIFICATION", request);
        this.$snotify.success(
          "Su codigo fue verificado con exito",
          "Felicidades"
        );
        this.close(true);
      } catch (error) {
        this.$snotify.error(error.response.data.message, "Error!");
      } finally {
        this.loading = false;
      }
    },
    close(val) {
      this.$emit("verification:change", val);
    },
  },
};
</script>

<style>
</style>