<template>
  <div>
    <header-component
      v-if="productsCategories.length"
      :productsCategories="productsCategories"
    />
    <div v-else style="height: 180px">
      <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
    </div>
    <v-sheet color="#F1F1F1">
      <v-container fluid>
        <v-progress-linear
          v-if="loadingProduct"
          indeterminate
          color="#A81331"
        ></v-progress-linear>
        <v-row justify="center">
          <v-col cols="12" sm="12" md="11">
            <v-row justify="center">
              <v-col cols="12" md="3" class="d-flex align-stretch my-5">
                <v-sheet
                  v-if="productsCategories.length"
                  width="100%"
                  height="100%"
                >
                  <div
                    class="px-5 pt-5"
                    v-for="(category, index) in productsCategories"
                    :key="index"
                  >
                    <v-hover v-slot="{ hover }">
                      <div
                        @click="HandlerFilterCategory(category, 1)"
                        class="text-capitalize"
                        :style="
                          hover
                            ? 'font-size: 15px; cursor: pointer; color: #A81331'
                            : 'font-size: 15px; cursor: pointer;'
                        "
                      >
                        {{ category.name }}
                      </div>
                    </v-hover>
                    <div
                      v-for="(sub_cat, index2) in category.sub_category"
                      :key="index2"
                      :class="!sub_cat.can_see ? 'mb-3' : 'mb-n7'"
                    >
                      <v-checkbox
                        v-if="sub_cat.can_see"
                        :label="subCatName(sub_cat.name)"
                        color="#A81331"
                        @change="HandlerFilterCategory(sub_cat, 2)"
                        v-model="sub_cat.value"
                      ></v-checkbox>
                    </div>
                    <v-divider class="mt-3"></v-divider>
                  </div>
                  <div
                    class="text-capitalize px-5 pt-5"
                    style="font-size: 15px"
                  >
                    Marcas
                  </div>
                  <div
                    v-for="(brand, u) in productsBrand"
                    :key="u + 'z'"
                    class="px-5"
                  >
                    <div class="mb-n7">
                      <v-checkbox
                        :label="subCatName(brand.name)"
                        color="#A81331"
                        v-model="brand.value"
                        @change="HandlerFilterBrands(brand)"
                      ></v-checkbox>
                    </div>
                  </div>
                  <div
                    @click="HandlerFilterCategory({}, 3)"
                    class="text-capitalize px-5 py-10"
                  >
                    <v-hover v-slot="{ hover }">
                      <div
                        class="text-capitalize"
                        :style="
                          hover
                            ? 'font-size: 17px; cursor: pointer; color: #A81331'
                            : 'font-size: 17px; cursor: pointer;'
                        "
                      >
                        Todo
                      </div>
                    </v-hover>
                  </div>
                </v-sheet>
                <v-sheet v-else width="100%" height="100%">
                  <v-skeleton-loader
                    v-for="(item, index) in 6"
                    :key="index"
                    class="mx-3 mt-2"
                    type="paragraph"
                  ></v-skeleton-loader>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="9" class="align-stretch my-5">
                <v-sheet color="white" class="mt-3">
                  <v-row v-if="productsData.length > 0">
                    <v-col
                      v-for="(item, index) in productsData"
                      :key="index"
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-card
                        v-if="item.product != null"
                        height="200"
                        class="elevation-0"
                        flat
                        @click="HandlerShowProduct(item)"
                      >
                        <img
                          v-if="item.images == null"
                          :aspect-ratio="4 / 3"
                          height="200"
                          width="100%"
                          contain
                          src="../../assets/img/no_image.jpg"
                          alt="noImage"
                        />
                        <div v-else>
                          <v-img
                            height="200"
                            width="100%"
                            contain
                            :src="item.images[0]"
                            :lazy-src="item.images[0]"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>
                        <v-card-text></v-card-text>
                      </v-card>
                      <p
                        class="
                          text-center
                          mb-1
                          title
                          font-weight-bold
                          text-uppercase
                        "
                      >
                        {{ item.keywords }}
                      </p>
                      <p class="text-center" v-if="item.price != null">
                        <span class="product-price">
                          ${{ item.price.pvp | currencyPVP }}
                        </span>
                      </p>
                      <cp-information
                        class="text-center"
                        :dataProduct="item"
                        :authUser="authUser"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else justify="center">
                    <v-col cols="12" md="9" class="d-flex justify-center">
                      <p class="text-center py-10 font-weight-medium">
                        No hay resultados para su busqueda
                      </p>
                    </v-col>
                  </v-row>
                  <v-row justify="center" class="mb-0">
                    <v-col v-if="paginationData.total > 0" cols="12" md="4">
                      <div class="text-center">
                        <v-pagination
                          color="#a81331"
                          v-model="page"
                          :length="paginationData.lastPage"
                        ></v-pagination>
                      </div>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import NavegationComponent from "@/components/Utils/navegation_component";
import informationCP from "@/components/Utils/informationCP";
import headerSheet from "./utilsComponents/products/headerSheet.vue";
export default {
  components: {
    "navegation-component": NavegationComponent,
    "cp-information": informationCP,
    "header-component": headerSheet,
  },
  data() {
    return {
      // Categories
      model: 1,

      loading: false,
      page: 1,
      productsCategories: [],
      productsFeature: [],
      paginate: {},

      // Marcas
      productsBrand: [],

      // Cateogry
      category_id: "",
      sub_category_id: "",
      categoriesArray: [],
      brands_id: [],
      everything: 1,
      feature_ids: [],
      isTodo: false,
    };
  },

  created() {
    if (this.$route.query.data == undefined) {
      this.category_id = null;
    } else {
      this.category_id = this.$route.query.data;
      this.categoriesArray = [];
    }

    if (this.$route.query.sub_data != undefined) {
      this.categoriesArray[0] = this.$route.query.sub_data;
    } else {
      this.categoriesArray = [];
    }

    if (this.$route.query.brand != undefined) {
      this.brands_id[0] = this.$route.query.brand;
    } else {
      this.brands_id = [];
    }
    this.HandlerGetProducts(this.page, this.isAuth);
    // this.HanderGetProductsBrand();
  },
  watch: {
    page(page) {
      this.HandlerGetProducts(page);
    },

    $route() {
      this.feature_ids = [];
      if (this.$route.query.data == undefined) {
        this.category_id = null;
      } else {
        this.category_id = this.$route.query.data;
        this.categoriesArray = [];
        this.everything = 1;
      }

      if (this.$route.query.sub_data != undefined) {
        this.categoriesArray[0] = this.$route.query.sub_data;
      } else {
        this.categoriesArray = [];
        this.everything = 1;
      }

      if (this.$route.query.brand != undefined) {
        this.brands_id[0] = this.$route.query.brand;
      } else {
        this.brands_id = [];
      }
      this.HandlerGetProducts(this.page, this.isAuth);
    },
  },

  filters: {
    currencyPVP(value) {
      if (value) {
        const AMOUNT_FORMAT = new Intl.NumberFormat("de-DE", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }).format(value);
        return AMOUNT_FORMAT;
      } else {
        return " ";
      }
    },
  },
  computed: {
    isAuth() {
      return this.$store.getters["auth/AUTHENTICATED"];
    },

    authUser() {
      return this.$store.getters["auth/GET_PROFILE"];
    },

    productsData() {
      return this.$store.getters["products/GET_PRODUCTS"];
    },

    paginationData() {
      return this.$store.getters["products/GET_PAGINATE_PRODUCT"];
    },
  },

  methods: {
    HandlerGetProducts(page) {
      this.HandlerGetPublicProducts(page, this.isAuth);
    },

    updateCategories() {
      // let valueNew = [];
      // for (const cat of this.categoriesArray) {
      //   valueNew.push(cat.toString());
      // }
      const valueNew = this.categoriesArray.map((cat) => cat.toString());
      this.categoriesArray = [...valueNew];

      if (this.$route.query.sub_data && this.categoriesArray.length === 0) {
        this.category_id = this.productsCategories[0].id;
        this.$router.push({
          path: this.$route.path,
          query: { data: this.category_id },
        });
      }
    },

    subCategoriesParse(sub_category) {
      for (const sub_cat of sub_category) {
        if (this.categoriesArray.includes(sub_cat.id.toString())) {
          sub_cat.value = true;
        } else {
          sub_cat.value = false;
        }

        if (["Portátiles", "Gaming", "Línea S"].includes(sub_cat.name)) {
          sub_cat.can_see = false;
        } else {
          sub_cat.can_see = true;
        }
      }
    },

    featuresParse(feature) {
      for (const features of feature) {
        if (this.feature_ids.includes(features.id.toString())) {
          features.value = true;
        } else {
          features.value = false;
        }
      }
    },

    brandParse(brand) {
      for (const brand of brand) {
        if (this.brands_id.includes(brand.id.toString())) {
          brand.value = true;
        } else {
          brand.value = false;
        }
      }
    },

    updateAttributes(categories, feature, brand) {
      for (const category of categories) {
        this.subCategoriesParse(category.sub_category);
        this.featuresParse(feature);
        this.brandParse(brand);
      }
    },

    async HandlerGetPublicProducts(page, isAuth) {
      try {
        this.loadingProduct = true;

        this.updateCategories();

        const warehouse_id =
          sessionStorage.getItem("region") === null
            ? 1
            : parseInt(sessionStorage.getItem("region"));

        const request = {
          store: process.env.VUE_APP_STORE_ID,
          page: page || 1,
          per_page: 12,
          paginate: true,
          warehouse_id: warehouse_id,
          keywords: this.$route.query.product ?? "",
          brand_ids: JSON.stringify(this.brands_id),
          sub_category_ids:
            this.categoriesArray.length === 0
              ? ""
              : typeof this.categoriesArray == "number"
              ? JSON.stringify([this.categoriesArray])
              : JSON.stringify(this.categoriesArray),
          category_ids: !this.category_id
            ? ""
            : JSON.stringify([this.category_id]),
          everything: this.everything,
          feature_ids: JSON.stringify(this.feature_ids),
          sort_position: "asc",
        };

        const response = await this.$store.dispatch(
          `products/${isAuth ? "GET_AUTH_PRODUCTS" : "GET_PRODUCTS"}`,
          request
        );

        // COLOCAR SUBCATEGORIA TRUE
        const categories = response.data.categories;
        const feature = response.data.feature;
        const brand = response.data.brand;
        this.updateAttributes(categories, feature, brand);

        const arrayFeaturesId = this.feature_ids.map((ids) => parseInt(ids));
        const featuresIDSFind = feature.map((ids) => {
          if (arrayFeaturesId.includes(ids.id)) {
            ids.value = true;
          } else {
            ids.value = false;
          }
          return ids;
        });

        this.productsCategories = categories;
        this.productsFeature = featuresIDSFind;
        this.productsBrand = brand;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingProduct = false;
      }
    },

    HandlerShowProduct(publication) {
      const encryptedID = this.CryptoJS.AES.encrypt(
        publication.product.id.toString(),
        "MyS3c3rtIdPr0Duct"
      ).toString();
      this.$router.push({
        name: "product_details",
        query: { data: encryptedID },
      });
    },

    subCatName(name) {
      return name[0].toUpperCase() + name.slice(1);
    },

    HandlerFilterCategory(value, action) {
      if (action == 2) {
        if (this.categoriesArray.includes(value.id.toString())) {
          const indexDelete = this.categoriesArray.findIndex(
            (val) => val == value.id.toString()
          );
          this.categoriesArray.splice(indexDelete, 1);
        } else {
          this.categoriesArray.push(value.id.toString());
        }
        if (this.categoriesArray.length > 0) {
          this.$router
            .push({
              path: this.$route.path,
              query: { sub_data: this.categoriesArray[0] },
            })
            .catch((err) => err);
        }
        this.everything = 1;
        this.HandlerGetProducts(this.page, this.isAuth);
      } else if (action == 1) {
        this.categoriesArray = [];
        this.category_id = value.id;
        this.$router
          .push({
            path: this.$route.path,
            query: { data: this.category_id },
          })
          .catch((err) => err);
        this.everything = 1;
        this.HandlerGetProducts(this.page, this.isAuth);
      } else {
        this.categoriesArray = [];
        this.category_id = null;
        this.$router.push(this.$route.path).catch((err) => err);
        this.everything = 0;
        this.feature_ids = [];
        this.isTodo = true;
        this.HandlerGetProducts(this.page, this.isAuth);
      }
    },

    HandlerFilterBrands(brand) {
      if (this.brands_id.includes(brand.id.toString())) {
        const indexDelete = this.brands_id.findIndex(
          (val) => val == brand.id.toString()
        );
        this.brands_id.splice(indexDelete, 1);
      } else {
        this.brands_id.push(brand.id.toString());
      }
      this.HandlerGetProducts(this.page, this.isAuth);
    },
  },
};
</script>

<style>
</style>
