<template>
  <v-menu
    open-on-hover
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-3"
        @click="HandlerRouter('cart')"
        icon
        color="#a81331"
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-cart-outline</v-icon>
      </v-btn>
    </template>

    <v-card
      v-if="
        productCartState.shopping_cart_items &&
        productCartState.shopping_cart_items.length
      "
    >
      <div
        class="pt-3 px-md-5 d-flex"
        v-for="(item, index) in productCartState.shopping_cart_items"
        :key="index"
      >
        <div>
          <v-avatar tile v-if="!item.publication.images">
            <img
              height="200"
              width="100%"
              contain
              src="@/assets/img/no_image.jpg"
              alt="noImg"
            />
          </v-avatar>
          <v-avatar v-else tile size="100">
            <v-img
              contain
              :src="item.publication.images[0]"
              :lazy-src="item.publication.images[0]"
              alt="Product Image"
            ></v-img>
          </v-avatar>
        </div>
        <div class="pl-3 align-self-center">
          <div class="">{{ item.publication.keywords }}</div>
          <div class="mt-2" v-if="item.publication && item.publication.price">
            $ {{ item.publication.price.pvp | currency }}
          </div>
        </div>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          dark
          small
          color="#a81331"
          rounded
          @click="HandlerRouter('cart')"
        >
          Lista de compra
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    productCartState: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      menu: false,
    };
  },

  methods: {
    HandlerRouter(router) {
      this.$router.push({ name: router }).catch((err) => {});
    },
  },
};
</script>

<style>
</style>