<template>
  <div>
    <caarrousel-component :carrusel="carrusel" />
    <v-container fluid>
      <categories-home v-if="categoria.length" :categoria="categoria" />
      <v-row v-else>
        <v-col cols="12" sm="3" md="3" v-for="(item, index) in 4" :key="index">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <section id="destacados">
      <v-responsive>
        <v-sheet color="#ECEBE5">
          <div class="d-flex justify-center pt-15">
            <span class="text-uppercase text-center mb-0 featured-title-home">
              Productos Destacados
            </span>
          </div>
          <div class="d-flex justify-center mt-0">
            <span
              class="text-uppercase text-center mt-n1 featured-subtitle-home"
            >
              Nuestros recomendados para vos
            </span>
          </div>
          <v-container :fluid="breakpoint > 900">
            <v-row justify="center">
              <v-col
                cols="11"
                :sm="breakpoint < 795 ? '12' : '10'"
                :md="breakpoint < 1200 ? '9' : '12'"
                :lg="breakpoint <= 1400 ? '11' : '10'"
                xl="8"
              >
                <carousel
                  v-if="featuredProductsFirst.length"
                  :navigationEnabled="true"
                  :navigation-next-label="nextLabel"
                  :navigation-prev-label="prevLabel"
                  :perPage="
                    breakpoint < 650
                      ? 1
                      : breakpoint > 650 && breakpoint <= 1000
                      ? 2
                      : 3
                  "
                  :paginationEnabled="
                    $vuetify.breakpoint.name == 'xs' ? false : true
                  "
                  paginationColor="#AF2641"
                  class="mt-10"
                >
                  <slide
                    v-for="(item, index) in featuredProductsFirst"
                    :key="index"
                    class="d-flex justify-center"
                  >
                    <v-card
                      :width="breakpoint < 1210 ? '18em' : '25em'"
                      height="auto"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-img
                          @click="HandlerProductDetails(item)"
                          style="cursor: pointer"
                          contain
                          :class="
                            hover ? 'animate__animated animate__pulse' : ''
                          "
                          class="mx-7 my-7"
                          width="auto"
                          height="300px"
                          :src="item.images[0]"
                          :lazy-src="item.images[0]"
                        >
                        </v-img>
                      </v-hover>
                      <v-card-text class="mx-3">
                        <v-row style="height: auto">
                          <v-col cols="12" class="mt-n5">
                            <span
                              @click="HandlerProductDetails(item)"
                              class="
                                black--text
                                text-uppercase
                                featured-title-product-home
                              "
                            >
                              {{ item.keywords }}
                            </span>
                          </v-col>
                          <v-col cols="12">
                            <div class="mr-3 mt-n5" v-html="item.resume"></div>
                          </v-col>
                          <v-col cols="12" class="align-self-end">
                            <div
                              v-if="item.price != null"
                              class="my-auto black--text featured-product-price"
                            >
                              $ {{ item.price.pvp | currency }}
                            </div>
                          </v-col>

                          <v-col>
                            <information-cp
                              class="text-start"
                              :dataProduct="item"
                              :authUser="authUser"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </slide>
                </carousel>
                <v-progress-linear
                  v-else
                  indeterminate
                  color="#A81331"
                ></v-progress-linear>
              </v-col>
              <v-col cols="12" md="12" class="d-flex justify-center mb-15">
                <v-btn
                  dark
                  @click="$router.push({ name: 'products' })"
                  style="border-width: medium"
                  rounded
                  color="#a81331"
                >
                  <span class="font-weight-medium"> Ir a la tienda </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-responsive>
    </section>
    <!-- <suscribe-component /> -->
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import CategoryComponent from "@/components/Utils/categories_component";
import SuscribeComponent from "@/components/Utils/suscribe_component";
import informationCP from "../Utils/informationCP.vue";
import CarrouselComponent from "./utilsComponents/home/CaarrouselComponent.vue";
import CategoriesHome from "./utilsComponents/home/CategoriesHome.vue";

export default {
  components: {
    "category-component": CategoryComponent,
    "suscribe-component": SuscribeComponent,
    "information-cp": informationCP,
    "caarrousel-component": CarrouselComponent,
    "categories-home": CategoriesHome,
    Carousel,
    Slide,
  },

  data() {
    return {
      featuredProductsFirst: [],

      // IMAGES
      carrusel: [],
      destacados: [],
      categoria: [],
      brandsData: [],

      isVisible: true,

      item: 0,

      //Carrusel
      perPage: 3,
    };
  },

  created() {
    this.HandlerGetProductFeatured();
    this.HandlerGetListPromotions();
    this.HandlerGetBrands();
  },

  watch: {
    isVisible(val) {
      if (val) this.$refs.slideGroup.setWidths();
    },

    authUser() {
      this.HandlerGetProductFeatured();
    },
  },

  computed: {
    authUser() {
      return this.$store.getters["auth/GET_PROFILE"];
    },

    breakpoint() {
      return this.$vuetify.breakpoint.width;
    },

    nextLabel() {
      return "<img src='../../../flacha-derecha.png' >";
    },

    prevLabel() {
      return "<img src='../../../flacha-izquierda.png' >";
    },
  },

  methods: {
    async HandlerGetProductFeatured() {
      try {
        const request = {
          store_id: process.env.VUE_APP_STORE_ID,
          warehouse_id: 3,
          limit: 9,
          paginate: true,
          buyer_id: this.authUser?.id ?? "",
        };
        const response = await this.$store.dispatch(
          "products/FEATURED_PRODUCTS",
          request
        );
        const arrayElemnt = response.data.data;

        this.featuredProductsFirst = arrayElemnt;
      } catch (error) {
        this.$snotify.error(
          "No se puede obtener los productos destacados",
          "Error!"
        );
      }
    },

    async HandlerGetListPromotions() {
      try {
        const request = {
          store_id: process.env.VUE_APP_STORE_ID,
          type: "",
          page: 1,
          per_page: 12,
          paginate: false,
        };

        const response = await this.$store.dispatch(
          "products/LIST_PROMOTIONS",
          request
        );

        for (const iterator of response.data.data) {
          if (iterator.active) {
            switch (iterator.type) {
              case "carrusel":
                this.carrusel.push(iterator);
                break;
              case "destacados":
                this.destacados.push(iterator);
                break;
              case "categorias":
                this.categoria.push(iterator);
                break;
            }
          }
        }
      } catch (error) {
        this.$snotify.error("No se puede obtener las promociones", "Error!");
      }
    },

    async HandlerGetBrands() {
      try {
        const request = {
          has_publications: true,
          store_id: process.env.VUE_APP_STORE_ID,
        };

        const response = await this.$store.dispatch(
          "brands/GET_BRANDS",
          request
        );
        this.brandsData = response.data.data;
      } catch (error) {
        this.$snotify.error("No se puede obtener las marcas", "Error!");
      }
    },

    HandlerProductDetails(product) {
      const encryptedID = this.CryptoJS.AES.encrypt(
        product.product_id.toString(),
        "MyS3c3rtIdPr0Duct"
      ).toString();
      this.$router.push({
        name: "product_details",
        query: { data: encryptedID },
      });
    },
  },
};
</script>

<style>
.title-des {
  font-size: 1.3vmax;
  color: #000;
  line-height: 24px;
  margin-bottom: 38px;
  font-weight: normal;
}
.title-des-description {
  left: 24px;
  line-height: 26px;
  padding-bottom: 22px;
  font-weight: normal;
  color: #fff;
  margin-bottom: 0;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  min-width: 1px;
}

.VueCarousel-navigation-next[data-v-453ad8cd] {
  right: 0;
  transform: translateY(-50%) translateX(100%);
  font-family: "system";
  color: #a81331;
}

.VueCarousel-navigation-prev[data-v-453ad8cd] {
  left: 0;
  transform: translateY(-50%) translateX(-100%);
  font-family: "system";
  color: #a81331;
}

/* .VueCarousel-navigation-next::before {
  content: url('../../assets/img/next.svg');
}
.VueCarousel-navigation-prev::before {
  content: url('../../assets/img/next.svg');
} */
</style>
