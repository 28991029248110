<template>
  <v-sheet>
    <v-img
      v-if="imageBanner() && productsCategories.length"
      contain
      :src="productsCategories[0].image_url"
      :lazy-src="productsCategories[0].image_url"
    >
      <v-container fill-height>
        <v-row justify="center" no-gutters>
          <v-col cols="12" class="d-flex justify-center">
            <div class="font-weight-bold d-flex">
              <span style="color: #a81331; font-size: 2.5em">
                {{ productsCategories[0].name }}
              </span>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <div style="color: white" class="d-flex">
              Descubre tu mejor opción
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
    <v-img
      v-else
      contain
      src="@/assets/img/banners/todos_180.webp"
      lazy-src="@/assets/img/banners/todos_180.webp"
    >
    </v-img>
  </v-sheet>
</template>

<script>
export default {
  props: {
    productsCategories: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    imageBanner() {
      const routeName = this.$route.query;
      if (!routeName.data && !routeName.sub_cat && !routeName.brand) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
</style>