<template>
  <v-container fluid>
    <div class="custom-nav" style="height: 75px">
      <div
        class="d-flex px-0 px-sm-15 px-md-15"
        style="height: 75px; align-items: center"
      >
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
          v-if="isMobile"
        ></v-app-bar-nav-icon>

        <v-avatar
          size="120"
          tile
          @click="$router.push({ name: 'home' }).catch((err) => err)"
        >
          <v-img
            style="cursor: pointer"
            contain
            @mouseover="megaMenu = false"
            src="@/assets/img/logo_stadio.svg"
            lazy-src="@/assets/img/logo_stadio.svg"
          ></v-img>
        </v-avatar>
        <div
          v-if="!$vuetify.breakpoint.smAndDown"
          class="px-5"
          @mouseover="megaMenu = false"
          style="color: white; cursor: default"
        >
          .
        </div>
        <div v-if="!isMobile">
          <v-hover v-slot="{ hover }">
            <span
              @mouseover="megaMenu = true"
              :style="
                hover ? 'cursor: pointer; color: #a81331' : 'cursor: pointer;'
              "
            >
              PRODUCTOS
            </span>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <span
              @mouseover="megaMenu = false"
              @click="goToContact"
              class="text-capitalize px-10"
              :style="
                hover ? 'cursor: pointer; color: #a81331' : 'cursor: pointer;'
              "
            >
              CONTACTO
            </span>
          </v-hover>

          <v-hover v-slot="{ hover }">
            <span
              @mouseover="megaMenu = false"
              @click="$router.push({ name: 'WhoAreWE' }).catch((err) => err)"
              class="text-capitalize px-0"
              :style="
                hover ? 'cursor: pointer; color: #a81331' : 'cursor: pointer;'
              "
            >
              QUIÉNES SOMOS
            </span>
          </v-hover>
        </div>

        <v-spacer></v-spacer>

        <!-- BUSCADOR -->
        <v-icon color="black" @click="activeSearch" class="mr-2">
          mdi-magnify
        </v-icon>

        <!-- PERFIL AUTENTICADO -->
        <div v-if="isAuth">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="$router.push({ name: 'profile' })"
                class="mr-1 ml-2 elevation-0"
                color="#E9E9E9"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="black">mdi-account-circle-outline </v-icon>
              </v-btn>
            </template>
            <span>Perfil</span>
          </v-tooltip>
        </div>

        <cart-component :productCartState="productCartState" />

        <!-- INGRESAR NO AUTENTICADO -->
        <v-btn
          v-if="!isMobile && !isAuth"
          class="text-capitalize"
          text
          @click="HandlerRouter('login')"
        >
          <v-icon class="mr-1">mdi-account-outline</v-icon>
          ingresar / Registrarme
        </v-btn>

        <!-- SALIR APP -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="isAuth"
              v-bind="attrs"
              v-on="on"
              class="ml-4"
              color="black"
              @click="HandlerLogout"
            >
              mdi-exit-to-app
            </v-icon>
          </template>
          <span>Salir</span>
        </v-tooltip>

        <v-btn
          v-if="isMobile && !isAuth"
          class="text-capitalize"
          text
          @click="$router.push({ name: 'login' })"
        >
          <v-icon class="">mdi-account</v-icon>
        </v-btn>
      </div>

      <!-- MEGA MENU -->
      <mega-menu-component
        v-if="megaMenu"
        :categories="categories"
        @close:change="closeMegaMenu"
      />
    </div>

    <v-navigation-drawer
      v-model="drawer"
      bottom
      color="transparent"
      fixed
      height="auto"
      overlay-color="secondary"
      overlay-opacity=".6"
      temporary
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-list color="white" shaped>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in drawerItems" :key="i">
            <v-list-item-content>
              <v-btn
                v-if="item.func == true"
                color="#2696A6"
                text
                class="font-medium text-capitalize"
                @click="HandlerRoute(item.href, item.value)"
              >
                {{ item.text }}
              </v-btn>
              <v-btn
                v-if="item.func == false"
                color="#2696A6"
                text
                @click="HandlerRoute(item.href, item.value)"
                class="font-medium text-capitalize"
              >
                {{ item.text }}
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import cartComponent from "./utils/cartComponent.vue";
import megaMenu from "./utils/megaMenu.vue";
export default {
  components: {
    "cart-component": cartComponent,
    "mega-menu-component": megaMenu,
  },

  data() {
    return {
      drawer: false,
      megaMenu: false,
      group: null,

      // search
      search: false,

      // CART
      fav: true,

      //Categories
      categories: [],
    };
  },

  created() {
    this.GetCategories();
  },

  watch: {
    isMobile(val) {
      if (!val) this.drawer = false;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    isAuth() {
      return this.$store.getters["auth/AUTHENTICATED"];
    },

    favoriteProduct() {
      return this.$store.getters["products/GET_PRODUCT_FAVORITES"];
    },

    drawerItems() {
      const isAuth = this.isAuth;
      let items;
      if (isAuth) {
        items = [
          { text: "Inicio", func: true, href: "home", value: "home" },
          { text: "Perfil", func: false, href: "profile", value: "profile" },
          {
            text: "Productos",
            func: false,
            href: "products",
            vlue: "products",
          },
          {
            text: "Carrito",
            func: false,
            href: "cart",
            value: "cart",
          },
          {
            text: "Favoritos",
            func: false,
            href: "product_favorite",
            value: "product_favorite",
          },
        ];
      } else {
        items = [
          { text: "Inicio", func: false, href: "home", value: "home" },
          {
            text: "Productos",
            func: false,
            href: "products",
            value: "products",
          },
        ];
      }
      return items;
    },

    productCartState() {
      return this.$store.getters["cart/CART_PRODUCTS"];
    },

    products() {
      const cart = this.$store.getters["cart/CART_PRODUCTS"];
      return cart.length == 0 ? [] : cart.shopping_cart_items;
    },
  },

  methods: {
    HandlerRouter(router) {
      this.$router.push({ name: router }).catch((err) => {});
    },

    HandlerRoute(route, value) {
      this.$router.push({ name: route }).catch((err) => {});
    },

    async HandlerLogout() {
      try {
        this.$store.commit("auth/CLEAR_DATA_LOGOUT");
        this.$store.commit("cart/CLEAN_CART");
        this.$router.push({ name: "home" }).catch((err) => err);
      } catch (error) {
        console.log(error);
      }
    },

    async GetCategories() {
      try {
        const request = {
          store: 1,
          page: 1,
          per_page: 1000,
          paginate: false,
          everything: false,
        };
        const response = await this.$store.dispatch(
          "products/GET_CATEGORIES",
          request
        );
        this.categories = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    activeSearch() {
      this.$store.commit("activeSearch");
    },

    goTo() {
      window.open("http://www.edifierla.com/hola/");
    },

    goToContact() {
      const url = process.env.VUE_APP_CHECKOUT;
      location.href = `${url}/contact`;
    },

    closeMegaMenu() {
      this.megaMenu = false;
    },
  },
};
</script>

<style>
.center-me {
  position: absolute;
  margin-left: 50%;
  transform: translate(-50%, 0);
}
.my-custom-dialog {
  align-self: flex-end;
}
.fade-transition-leave-active {
  position: absolute;
  width: 100%;
}

.mega_menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  margin-left: 0px;
  -webkit-box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 30%);
  box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 30%);
}

.custom-nav {
  height: 76px;
  position: fixed;
  z-index: 2;
  background-color: #fff;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-box-shadow: 0 3px 5px 0 rgb(0 0 0 / 30%);
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 30%);
}
</style>
