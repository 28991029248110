<template>
  <v-dialog v-model="showRecovery" max-width="500">
    <ValidationObserver ref="obsRec" v-slot="{ passes }">
      <v-card
        class="animate__animated animate__fadeIn animate__faster"
        :loading="loading_verification"
        v-if="!showNotificationEmail"
      >
        <v-card-title>
          Ingresa tu correo para recuperar tu cuenta
        </v-card-title>
        <v-card-text class="mt-2 mb-0 pb-0">
          <ValidationProvider
            name="correo"
            rules="required|email"
            v-slot="{ errors }"
          >
            <v-text-field
              @keyup.enter="passes(HandlerRecoveryAccount)"
              v-model="email_verifiction"
              label="Correo Electrónico"
              dense
              filled
              color="#A81331"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading_verification"
            @click="close()"
            dark
            color="grey darken-3"
            text
          >
            Cancelar
          </v-btn>
          <v-btn
            color="#A81331"
            :loading="loading_verification"
            @click="passes(HandlerRecoveryAccount)"
            dark
            rounded
          >
            Recuperar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else class="animate__animated animate__fadeIn animate__faster">
        <v-card-text>
          <v-alert
            class="pt-7"
            icon="mdi-check-outline"
            prominent
            text
            type="success"
          >
            Se ha enviado un correo para que modifique su contraseña
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              () => {
                showRecovery = false;
                showNotificationEmail = false;
                email_verifiction = '';
              }
            "
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showRecovery: {
      type: Boolean,
      default: false,
      required: true,
    },
    showNotificationEmail: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      loading_verification: false,
      email_verifiction: "",
    };
  },

  methods: {
    async HandlerRecoveryAccount() {
      try {
        this.loading_verification = true;
        const request = {
          email: this.email_verifiction,
          url_base: process.env.VUE_APP_CHECKOUT,
          store_id: process.env.VUE_APP_STORE_ID,
        };
        await this.$store.dispatch("auth/RECOVERY_PASSWORD", request);
        this.showNotificationEmail = true;
      } catch (error) {
        this.$snotify.error(error.response.data.error.err_message, "Error!");
      } finally {
        this.loading_verification = false;
      }
    },

    close() {
      this.$emit("recoveryPassword:change");
    },
  },
};
</script>

<style>
</style>